import FF from "./FormField.module.css";

import type { ReactNode } from "react";
import { RadioGroup, type RadioGroupProps } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { FormFieldChildren, type FormFieldChildrenProps } from "./FormField.tsx";
import { standardFieldAttributes, type StandardFieldProps } from "./standardFieldAttributes.tsx";

interface WRadioGroupProps<T extends string>
    extends Omit<RadioGroupProps, "children" | "className" | "onChange">,
        StandardFieldProps,
        FormFieldChildrenProps {
    // Current value
    // (Improve the typing of react-aria-components RadioGroup, which always assumes string values)
    value?: T;
    defaultValue?: T;
    onChange?: (value: T) => void;

    // Rendering
    className?: string;
    wide?: boolean;
    children: ReactNode;
    block?: boolean;
}

export function WRadioGroup<T extends string>({ children, className, onChange, block, ...props }: WRadioGroupProps<T>) {
    return (
        <RadioGroup
            className={cn(FF.FormField, className, block && FF.Block)}
            {...standardFieldAttributes(props)}
            {...props}
            onChange={onChange as RadioGroupProps["onChange"]}
        >
            <FormFieldChildren {...props}>
                <div className={FF.FormFieldRadioGroup}>{children}</div>
            </FormFieldChildren>
        </RadioGroup>
    );
}
