import FF from "./FormField.module.css";

import type { ReactNode } from "react";
import { Radio } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { MaskIcon } from "../icon/MaskIcon.tsx";

export interface IconRadioProps {
    id: string;
    title: ReactNode;
    icon: string;
    desc?: string;
    wide?: boolean;
}

export function IconRadio({ icon, id, title, desc, wide }: IconRadioProps) {
    return (
        <Radio value={id} className={cn(FF.FormFieldIconRadio, wide && FF.wide)}>
            <MaskIcon className={`${icon} size-2.5rem`} />
            {desc ? (
                <div className={FF.content}>
                    <div className={FF.title}>{title}</div>
                    <div>{desc}</div>
                </div>
            ) : (
                <div className={FF.title}>{title}</div>
            )}
        </Radio>
    );
}
